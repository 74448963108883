import { WebAuth } from 'auth0-js';

import { Main } from '@/pages/common';

import { NoEnvironment } from './[envid]/destination/list/components/NoEnvironment';

interface PageProps {
  authSession: string;
  authService: WebAuth;
}

export default function App({ authSession, authService }: PageProps) {
  return (
    <Main authSession={authSession} authService={authService}>
      <NoEnvironment />
    </Main>
  );
}
